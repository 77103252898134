import "./App.css";
import { Navigate, useLocation } from "react-router-dom";
import { Routes, Route, NavLink } from "react-router-dom";
import Headerbar from "./Common/Components/Navbar/HeaderBar.js";
import Footer from "./pages/Footer.js";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TabBar from "./Common/Components/Navbar/TabBar";
import Cart from "./pages/Cart/Cart";
import Checkout from "./pages/Checkout/Checkout";
import CategoryPopUP from "./pages/Categories/CategoryPopUp"
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
// import IdleTime from "./IdleTime";
import { useNavigate } from "react-router-dom";
import {
  setCoordinate,
  setUserCoordinate,
  setFlag,
  setUserFlag,
  setNearestStore,
  setStoreCoordinate,
  setStoreData,
  setLocationPopup,
  setRouteError,
  setStoreMarkers,
  setStoreIndexValue,
  setCheckScroll,
} from "./Redux/StoreSlice";
import { getStoreByCustomerLocation, getStoreStartendTime } from "./Api/API";
import SignUp from "./pages/SignUp/SignUp";
import Login from "./pages/Login/Login";
import Rewards from "./pages/Rewards/Rewards";
import SettingTabBar from "./Common/Components/Navbar/SettingTabBar";
import OrderHistory from "./pages/OrderHistory/OrderHistory";
import Payment from "./pages/Payment/Payment";
import Favorites from "./pages/Favorites/Favorites";
import { setDeny } from "./Redux/StoreSlice";
import AlertPopup from "./Common/AlertPopup";
import CardDetail from "./pages/Setting.js/CardDetail";
import { setUpdateCart } from "./Redux/CartSlice";
import { handleStoreStartEndTime } from "./Common/utils";
import SocialButton from "./pages/Login/SocialButton";
import MenuScreen from "./pages/Categories/MenuScreen";

import AddCardMobile from "./pages/Setting.js/AddCardMobile";
import PosCardEntry from "./pages/Payment/PosCardEntry";
import KioskReceipt from "./pages/KioskReceipt/KioskReceipt";
import KioskReceipt2 from "./pages/KioskReceipt/KioskReceipt2.js";
import { decode } from "base-64";
function App() {
  const dispatch = useDispatch();
 
 
  // const timer = IdleTime(1800);
  let navigate = useNavigate();
  const flag = useSelector((state) => state.store.flag);
  const userFlag = useSelector((state) => state.store.userFlag);
  const [state, setState] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const isLogin = useSelector((state) => state.user.isLogin);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const cartItem = useSelector((state) => state.cart.cart);
  const isUsercheckout = useSelector((state) => state?.user.isCheckout);
  let storeCoordinates = [];
  let location = useLocation();
  let search = useLocation().search;
  const deviceType = new URLSearchParams(search).get("deviceType")
  let kioskDeviceType=deviceType;
  if(location.pathname==="/kiosk/downloadReceipt"&&!deviceType){
     kioskDeviceType = new URLSearchParams(decode(search.substring(1))).get("deviceType")
  }

  // let search = useLocation().search;
  // Notification.requestPermission().then(function (result) {
  //   console.log(result);
  // });
 
  useEffect(() => {
    if(kioskDeviceType !== "kiosk"||deviceType !== "pos"&&location.pathname!=="/kiosk/downloadReceipt"){
  const searchParams = new URLSearchParams(location.search);
   let qlat = searchParams.get("latitude")
   let qlng = searchParams.get("longitude")
    let pathname = {coords :{latitude:qlat ? Number(qlat) : null,
    longitude: qlng ? Number(qlng) : null}}
    if(pathname.coords.latitude && pathname.coords.longitude && flag === true){
      success(pathname)
      dispatch(setFlag(false));
    }
    else if(userFlag == true){
      navigator.geolocation.getCurrentPosition(success, error);
      dispatch(setUserFlag(false));
    }
    else{
    if (flag === true && deviceType != "mobile") {
      navigator.geolocation.getCurrentPosition(success, error);      
    }
    dispatch(setFlag(false));  
  }
}
  }, [userFlag]);







  const error = (err) => {
    if(((!deviceType || deviceType == "web") && flag ==true )){
      setOpenAlert(true);
    }else{
   
    }
    // dispatch(setDeny(true));
    // dispatch(setLocationPopup(true));
  };

  const success = (position) => {
    const coordinates = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    //  coordinates of user
    dispatch(setDeny(false));
    setState(true)
    dispatch(setCoordinate(coordinates));
    dispatch(setUserCoordinate(coordinates));

    if (coordinates) {
      setStoreLoading(true)
      const fetchStoreData = async () => {  
    await  getStoreByCustomerLocation(coordinates.lat, coordinates.lng)
        .then((res) => {
          res?.result.map((item, index) => {
            storeCoordinates.push({
              lat: item?.latitude,
              lng: item?.longitude,
              item: item,
            });
          });
          /* eslint-disable no-undef */          
          for (var i = 0; i <= storeCoordinates.length; i++) {
            if (i < storeCoordinates.length) {
              m_get_directions_route(storeCoordinates[i], i);
            }
          }
        })
        .catch((e) => console.log(e));
      }
      fetchStoreData()
    }
  };



  var delayFactor = 0;
  let storeDetail = [];
  let marker = [];
  let indexArray = []

  function m_get_directions_route(storeCoordinate, index ) {

    let time = Math.round(storeCoordinate.item.distance / 0.5);
    var Hours = Math.floor(time / 60);
    var minutes = time % 60;
    var duration= Hours > 0 ? `${Hours} hour ${minutes}` : minutes
    storeCoordinate.item = {
      ...storeCoordinate.item,
      ["distance"]: storeCoordinate.item?.distance?.toFixed(2).toString(),
      // ["duration"]: result?.routes[0]?.legs[0]?.duration?.text,
      ["duration"]: duration,
    };
        let detail = [];
        if (storeCoordinate.item.store_name && storeCoordinate.item.id) {
          detail.push(storeCoordinate.item);
        }
        const image =
          "./red_dot.png";
        let markerArray = [];
        if (storeCoordinate.item.store_name && storeCoordinate.item.id) {
          markerArray.push({
            id: storeCoordinate?.item?.id,
            name: storeCoordinate?.item?.store_name,
            position: {
              lat: Number(storeCoordinate.lat),
              lng: Number(storeCoordinate.lng),
            },
            icon: image,
          });
        }
        indexArray.push(index)
        storeDetail = [...storeDetail, ...detail];
        marker = [...marker, ...markerArray];
        storeDetail?.sort((a, b) => Number(a.distance) - Number(b.distance));
        
        if (storeDetail.length > 0 && flag == true ) {
          dispatch(setUpdateCart([]));
          setStoreLoading(false);
          if (storeCoordinates.length == indexArray.length) {
          getStoreStartendTime(storeDetail[0]?.id).then((res) => {
            let storeStatus = handleStoreStartEndTime(res,storeDetail[0]?.time_zone);
           let timeRange = storeStatus?.timeRange;
           if( storeStatus?.status[storeStatus?.timeZoneWeekDay].storeDayStatus == false &&
            storeStatus?.status[storeStatus?.timeZoneWeekDay]?.onLineStatus == "on" &&
            timeRange == true ){
              dispatch(setNearestStore(storeDetail[0]));
            }
            if(Math.max(...indexArray) == (storeDetail.length || storeDetail.length - 1) ){
              dispatch(setStoreIndexValue(Math.max(...indexArray)));            
            }
          
          });
        
             dispatch(setStoreMarkers(marker))
             dispatch(setStoreData(storeDetail));
          dispatch(
            setStoreCoordinate({
              lat: Number(storeDetail[0].latitude),
              lng: Number(storeDetail[0].longitude),
            })
          );
          }
       ;
          dispatch(setRouteError(""));
        }
       else if (storeDetail.length == storeCoordinates.length && userFlag == true ) {
        setStoreLoading(false);
        if(Math.max(...indexArray) == (storeDetail.length || storeDetail.length - 1) ){
          dispatch(setStoreIndexValue(Math.max(...indexArray)));            
        }
        dispatch(setStoreData(storeDetail));
        dispatch(
          setStoreCoordinate({
            lat: Number(storeDetail[0].latitude),
            lng: Number(storeDetail[0].longitude),
          })
        );
          dispatch(setCheckScroll(true))  
        dispatch(setRouteError(""));
       }

  }
  
  useEffect(()=>{
    if(deviceType !== "mobile" ){
      let Tab = sessionStorage.getItem("newTab");
      if(Tab == "true"){
      }
      else{
        if(cartItem.length!=0){
          localStorage.clear();
          navigate("/")
          window.location.reload();    
        }else{
          const handleVisibilityChange = () => {
            if (document.visibilityState === "hidden" && !document.hasFocus()) {
              // Clear local storage
              window.localStorage.clear();
              // navigate("/")
      
              // Clear cache
              window.caches.keys().then(function(keyList) {
                return Promise.all(keyList.map(function(key) {
                  return window.caches.delete(key);
                }));
              });
            }
          };
      
          document.addEventListener("visibilitychange", handleVisibilityChange);
      
          return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
          };
        }
          
      }
      sessionStorage.setItem("newTab", "true");
      }
  },[])

  // console.log(deviceType)
  // if (timer == 0){
  //   localStorage.clear();
  //   navigate("/")
  //   window.location.reload();
  // }
 
// console.log(checkoutFlag,"isCheckOutByUser",checkout)
  return (
    <>
      <div className="root1">
        {location.pathname == "/payment" ||
        location.pathname.indexOf("/payment") != -1 || location.pathname == "/addCard" || location.pathname.indexOf("/addCard") != -1 ||location.pathname == "/manualCardEntry" ||
        location.pathname.indexOf("/manualCardEntry") != -1 ||location.pathname == "/kiosk/downloadReceipt" ||
        location.pathname.indexOf("/kiosk/downloadReceipt") != -1? (
          (deviceType == "mobile"||deviceType == "pos"||kioskDeviceType == "kiosk") ? (
            ""
          ) : !deviceType ? "" :(
            // <Headerbar noOptions={true} url={location.search}  setState={setState} state ={state}/>
          <>
          <Headerbar setState={setState} state ={state} storeLoading={storeLoading} setStoreLoading={setStoreLoading} />
          {(location.pathname === "/menu"||location.pathname === "/customization")&&
          <TabBar setState={setState} />}
          </>

          )
        ) : (
          <>
          <Headerbar  setState={setState} state ={state} storeLoading={storeLoading} setStoreLoading={setStoreLoading} />
          {(location.pathname === "/menu"||location.pathname === "/customization")&&
          <TabBar setState={setState} />}
          </>

        )}
        
        {(deviceType === "mobile"||deviceType === "pos"||kioskDeviceType === "kiosk"||location.pathname==="/kiosk/downloadReceipt")? " " :
         <Footer setState={setState} /> }
        <Routes>
          <Route exact path="/" element={<TabBar setState={setState} />} />
            <Route
            exact
            path="/kiosk/downloadReceipt"
            element={<KioskReceipt2 />}
          />
          <Route exact path="/menu" element={<MenuScreen setState={setState}/>} />
          <Route exact path="/customization" element={<CategoryPopUP />} />
          <Route exact path="/setting" element={isLogin? <SettingTabBar /> : <TabBar/> } />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/checkout" element={((isLogin||isGuestLogin)&&isUsercheckout)?<Checkout />:<Navigate to="/" replace />} />
          <Route exact path="/payment" element={<Payment />} />
          <Route exact path="/payment/success" element={<Payment />} />
          <Route exact path="/payment/failure" element={<Payment />} />
          <Route exact path="/rewards" element={<Rewards />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/orderHistory" element={<OrderHistory />} />
          <Route exact path="/favorites" element={<Favorites />} />
          <Route exact path="/saveCard" element={<CardDetail/>}/>
          <Route exact path="/addCard" element={<AddCardMobile/>}/>
          <Route exact path="/addCard/success" element={<AddCardMobile/>}/>
          <Route exact path="/addCard/failure" element={<AddCardMobile/>}/>
          <Route exact path="/manualCardEntry" element={<PosCardEntry/>}/>
        </Routes>
      </div>
      <div style = {{display: "none"}}>
      <SocialButton
                        provider="google"
                        appId="582546805022-cua7jqr50ar1s334p914oso1patlk192.apps.googleusercontent.com"
                      >
                      </SocialButton>
                      </div>
      <AlertPopup
        open={openAlert}
        setOpen={setOpenAlert}
        setState={setDeny}
        title={"locationAccess"}
        displayTitle={"Location access is blocked. Change your location settings in browser or select location manually"}
        buttonType2={true}
      />
    </>
  );
}

export default App;
